@import url("/assets/fonts/font.css");


/**
 * Reset some basic elements
 */
body, h1, h2, h3, h4, h5, h6,
p, blockquote, pre, hr,
dl, dd, ol, ul, figure {
  margin: 0;
  padding: 0;
}



/**
 * Basic styling
 */
body {
  background-color: $primary;
  font: $base-font-weight #{$base-font-size}/#{$base-line-height} $base-font-family;
  color: $positive;
  -webkit-text-size-adjust: 100%;
  -webkit-font-feature-settings: "kern" 1;
     -moz-font-feature-settings: "kern" 1;
       -o-font-feature-settings: "kern" 1;
          font-feature-settings: "kern" 1;
  font-kerning: normal;
  min-height: 100vh;
  overflow: auto!important;

  &.alt-body {
    background-color: $secondary;
  }
}



/**
 * Set `margin-bottom` to maintain vertical rhythm
 */
h1, h2, h3, h4, h5, h6,
p, blockquote, pre,
ul, ol, dl, figure,
%vertical-rhythm {
  margin-bottom: $spacing-unit;
}



/**
 * `main` element
 */
main {
  display: block; /* Default value of `display` of `main` element is 'inline' in IE 11. */
}



/**
 * Images
 */
img {
  max-width: 100%;
  vertical-align: middle;
}



/**
 * Figures
 */
figure > img {
  display: block;
}

figcaption {
  font-size: $small-font-size;
}



/**
 * Lists
 */
ul, ol {
  margin-left: $spacing-unit;
}

li {
  > ul,
  > ol {
    margin-bottom: 0;
  }
}



/**
 * Headings
 */
h1 {
  color: $positive;
  font-family: $base-header-font-family;
  font-weight: normal;
  line-height: 0.8;
  margin-bottom: $spacing-unit*2.1;
  text-transform: uppercase;
}
h2, h3, h4, h5, h6 {
  font-family: $base-header-font-family-alt;
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: $spacing-unit*2.1;
}
h1 {
  @include relative-font-size(6);

  @include media-query($on-800) {
    @include relative-font-size(4);
  }

  @include media-query($on-400) {
    @include relative-font-size(3);
  }
}

h2 {
  @include relative-font-size(3);

  @include media-query($on-800) {
    @include relative-font-size(2.5);
  }

  @include media-query($on-400) {
    @include relative-font-size(2);
  }
}

h3 {
  @include relative-font-size(2.5);

  @include media-query($on-800) {
    @include relative-font-size(2);
  }

  @include media-query($on-400) {
    @include relative-font-size(1.5);
  }
}

h4 {
  @include relative-font-size(2);

  @include media-query($on-800) {
    @include relative-font-size(1.5);
  }

  @include media-query($on-400) {
    @include relative-font-size(1);
  }
}

h5 {
  @include relative-font-size(1.5);

  @include media-query($on-800) {
    @include relative-font-size(1);
  }
}

h6 {
  @include relative-font-size(1);
}



/**
 * Links
 */
a {
  color: $link;

  &:visited {
    color: $link;
  }

  &:hover {
	color: $hover;
    text-decoration: none;
  }

  .social-media-list &:hover {
    text-decoration: none;
  }
}



/**
 * Custom Paragraphs
 */
.woodstamp {
  font-family: $base-header-font-family;

  @include relative-font-size(2);

  @include media-query($on-laptop) {
    @include relative-font-size(2);
  }
}
p.focus {
  font-weight: 700;
  text-transform: uppercase;
}



/**
 * Buttons
 */
button,
a.button,
button.button,
submit,
submitbutton,
input.button,
input[type=submit] {
  background: $negative;
	border: none;
  border-radius: 100px;
  color: $positive;
  font-family: $base-font-family;
  padding: ($spacing-unit * 1) ($spacing-unit * 2);
  text-decoration: none;
	text-transform: uppercase;
	width: initial;

  &:hover,
  &:focus {
    color: $secondary;
  }
}

.link-box {
	margin: $spacing-unit*4 $spacing-unit*2;
	
	a,span {
		border: 2px solid $secondary;
		padding: $spacing-unit*2;
		
		&:visited {
			border-color: $positive;
		}		
		&:hover {
			border-color: $secondary;
		}
	}
}

/**
 * Smooth transitions
 */

a,
button,
input:focus,
input[type="button"],
input[type="reset"],
input[type="submit"],
textarea:focus,
.button,
.gallery img {
	-webkit-transition: all 0.1s ease-in-out;
	-moz-transition:    all 0.1s ease-in-out;
	-ms-transition:     all 0.1s ease-in-out;
	-o-transition:      all 0.1s ease-in-out;
	transition:         all 0.1s ease-in-out;
}


/**
 * Blockquotes
 */
blockquote {
  color: $positive;
  border-left: 4px solid $positive;
  padding-left: $spacing-unit / 2;
  @include relative-font-size(1.125);
  letter-spacing: -1px;
  font-style: italic;

  > :last-child {
    margin-bottom: 0;
  }
}



/**
 * Code formatting
 */
pre,
code {
  @include relative-font-size(0.9375);
  border: 1px solid $positive;
  border-radius: 3px;
  background-color: $negative;
}

code {
  padding: 1px 5px;
}

pre {
  padding: 8px 12px;
  overflow-x: auto;

  > code {
    border: 0;
    padding-right: 0;
    padding-left: 0;
  }
}



/**
 * Container
 */

.container {
	background: $primary;
  background-image: url(/images/2024/banner/DOTS_PINK.svg), url(/images/2024/banner/DOTS_PINK.svg);
  background-repeat: no-repeat, no-repeat;
  background-size: 1000px;
  background-position: left -500px top -500px, right -500px bottom -500px;
  color: $on-primary;
	padding: 40px;
	border-radius: 10px;
  margin-bottom: 40px;
	@extend %clearfix;

  h2 {
    color: $secondary;
  }

  img {
    border-radius: 10px;

  }

	@include media-query($on-800) {
		background-size: 800px;
		background-position: left -400px top -400px, right -400px bottom -400px;

	}

	@include media-query($on-600) {
		background-size: 600px;
		background-position: left -300px top -300px, right -300px bottom -300px;

	}

	@include media-query($on-400) {
		background-size: 400px;
		background-position: left -200px top -200px, right -200px bottom -200px;

	}
}


/**
 * Wrapper
 */
.wrapper {
  max-width: -webkit-calc(#{$content-width} - (#{$spacing-unit} * 2));
  max-width:         calc(#{$content-width} - (#{$spacing-unit} * 2));
  margin-right: auto;
  margin-left: auto;
  padding-right: $spacing-unit*4;
  padding-left: $spacing-unit*4;
  @extend %clearfix;

  @include media-query($on-laptop) {
    max-width: -webkit-calc(#{$content-width} - (#{$spacing-unit}));
    max-width:         calc(#{$content-width} - (#{$spacing-unit}));
    padding-right: $spacing-unit*2;
    padding-left: $spacing-unit*2;
  }
}



/**
 * Clearfix
 */
%clearfix:after {
  content: "";
  display: table;
  clear: both;
}



/**
 * Icons
 */

.svg-icon {
    width: 16px;
    height: 16px;
    display: inline-block;
    fill: #{$positive};
    padding-right: 5px;
    vertical-align: text-top;
}

.social-media-list {
  li + li {
    padding-top: 5px;
  }
}

.arrow-up {
  width: 0; 
  height: 0; 
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 15px solid $secondary;
  margin: 0 auto;

  &:hover, &:focus {
    border-bottom: 15px solid $on-primary;
  }
}



/**
 * Tables
 */
table {
  margin-bottom: $spacing-unit;
  text-align: $table-text-align;
  color: $positive;
  border-collapse: collapse;
  border: 1px solid $positive;
  margin: $spacing-unit*4 auto;
  tr {
    &:nth-child(even) {
      background-color: $positive;
    }
  }
  th, td {
    padding: $spacing-unit;
  }
  th {
    background-color: $positive;
    border: 1px solid $positive;
    border-bottom-color: $positive;
  }
  td {
    border: 1px solid $positive;
  }
}

/**
 * Forms
 */

form {
	color: $positive;
	margin: ($spacing-unit * 2) 0;
	max-width: calc(100% - (#{$spacing-unit} * 4));
	
	p {
		font-size: 80%;
		margin-bottom: $spacing-unit*2;
	}
}
input,
textarea {
	font-family: $base-font-family;
	font-size: $base-font-size;
	border: 1px solid $positive;
	width: 100%;
	padding: $spacing-unit * 2;
	margin-bottom: $spacing-unit * 2;
	
	&[type=checkbox] {
		margin: 0 $spacing-unit 0 0;
		width: auto;
	}
}
textarea {
	min-height: $spacing-unit * 10;
}

/**
 * Lists
 */

ul.list {
	margin: 0;
	margin-top: $spacing-unit*3;
}
ul.list li {
	list-style: none;
	float: left;
	margin-bottom: $spacing-unit*3;
}

li.full {
	width: 100%;
}
li.half {
	width: 48.5%;
	margin-right: 3%;
	
	&:nth-child(2n+2) { margin-right: 0; }
	
	@include media-query($on-palm) {
		width: 100%;
		margin-right: 0;
	}
}
li.third {
	width: 31.3%;
	margin-right: 3%;
	
	&:nth-child(3n+3) { margin-right: 0; }
	
	@include media-query($on-laptop) {
		width: 48.5%;
		&:nth-child(3n+3) { margin-right: 3%; }
		&:nth-child(2n+2) { margin-right: 0; }
	}
	@include media-query($on-palm) {
		width: 100%;
		margin-right: 0;
	}
}
li.quarter {
	width: 22.7%;
	margin-right: 3%;
	
	&:nth-child(4n+4) { margin-right: 0; }
	
	@include media-query($on-laptop) {
		width: 48.5%;
		&:nth-child(3n+3) { margin-right: 3%; }
		&:nth-child(2n+2) { margin-right: 0; }
	}
	@include media-query($on-palm) {
		width: 100%;
		margin-right: 0;
	}
}
li.last {
	margin-right: 0;
}
li img {
	min-width:100%;
}