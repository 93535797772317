/* Home Page
----------------------------- */

.home {
	background-color: $primary;
    background-image: url(/images/2024/backgrounds/BLAST_PINK.svg);
    background-repeat: no-repeat;
    background-size: contain;
}

/* About Page
------------------------------*/

.about-page {  
	background-color: $primary;
	background-image: url(/images/2024/backgrounds/BLAST_PINK.svg);
	background-position: top center;
	background-repeat: no-repeat;
	background-size: contain;

	p {
		color: $on-primary;
	}
	h1 {
		color: $secondary;
	}
	img:nth-child(1) {
		margin-bottom: $spacing-unit*6;
	}
	.post-content {
		h2 {
			@include relative-font-size(2);
		}
		h3 {
			@include relative-font-size(1);
		}
		div {
			// border-bottom: 4px solid $brand-color;
			margin-bottom: $spacing-unit*3;
			width:100%;
		}
		.charity-logo {
			max-width: 300px;
			width: 100%;
		}
	}
}

/* Contact Success Page
------------------------------*/

.contact-success-page {  
	background-color: $secondary;
	background-image: url(/images/2024/backgrounds/BLAST_YELLOW.svg);
	background-position: top center;
	background-repeat: no-repeat;
	background-size: contain;

	a {
		display: inline-block;
		margin: 40px auto;

		&.button:hover,
		&.button:focus {
			background-color: $primary;
			color: $on-primary;
		}
	}
	p {
		color: #000;
	}
	h1 {
		color: $primary;
	}
}

/* Charities Page
------------------------------*/

.charities-page {
	background-color: $primary;
	background-image: url(/images/2024/backgrounds/BLAST_PINK.svg);
	background-position: top center;
	background-repeat: no-repeat;
	background-size: contain;

	h1 {
		color: $secondary;
	}
	.post-content {
		p {
			color: $on-primary;
		}
		h2 {
			color: $secondary;
			@include relative-font-size(2);
		}
		h3 {
			color: $secondary;
			@include relative-font-size(1);
		}
		div {
			// border-bottom: 4px solid $brand-color;
			margin-bottom: $spacing-unit*3;
			width:100%;
		}
		.charity-logo {
			max-width: 300px;
			width: 100%;
		}
	}
}

/* Gallery Page
------------------------------*/

.gallery-page {
	background-color: $secondary;
	background-image: url(/images/2024/backgrounds/BLAST_YELLOW.svg);
	background-position: top center;
	background-repeat: no-repeat;
	background-size: contain;

	h1 {
		color: $primary;
	}
	a.button:hover,
	a.button:focus {
		background-color: $primary;
		color: $positive;
	}
}

/* Gallery Page (Batman)
------------------------------*/

.bacc-gallery-page {
	background-color: $primary;
	background-image: url(/images/2024/backgrounds/BLAST_PINK.svg);
	background-position: top center;
	background-repeat: no-repeat;
	background-size: contain;
	text-align: center;

	h1 {
		color: $secondary;
	}

	p {
		margin-top: 40px;
	}

	a.button:hover,
	a.button:focus {
		background-color: $secondary;
		color: $positive;
	}
			
	@include media-query($on-600){
		
		.title-image {
			max-height: 200px;
		}
	}
}

/* Guests Page
------------------------------*/

.guests-page {
	background-color: $secondary;
	background-image: url(/images/2024/backgrounds/BLAST_YELLOW.svg);
	background-position: top center;
	background-repeat: no-repeat;
	background-size: contain;
	color: $on-secondary;

	article header div {
		max-width: 700px;
	}

	h1,h2 {
		color: $primary;
	}
	h3 {
		color: $secondary;
	}
	a.button:hover,
	a.button:focus {
		background-color: $primary;
		color: $positive;
	}

	.guest {
		color: $primary;
	}
}

/* Privacy Page
------------------------------*/

.privacy-page {
	background-color: $secondary;

	a {
		color: $primary;

		&.button:hover,
		&.button:focus {
			background-color: $primary;
			color: $on-primary;
		}
	}
	p {
		color: #000;
	}
	h1,h4,h6 {
		color: $primary;
	}
	h3 {
		color: $primary;

		@include relative-font-size(1.2);
		font-family: "Anton", sans-serif;
		font-weight: 100;
	}
	::marker {
		color: $on-secondary;
	}
}

/* Prize Draw Page
------------------------------*/

.prize-draw-page {
	background-color: $primary;
	background-image: url(/images/2024/backgrounds/BLAST_PINK.svg);
	background-position: top center;
	background-repeat: no-repeat;
	background-size: contain;

	h1 {
		color: $secondary;
	}	
	ul {
		text-align: center;
		list-style: none;

		li {
			.prize {
				font-size: 1.6em;
				font-weight: 700;
			}
			.donor {
				display: block;
				font-size: initial;

				.donor {
					display: inline;
					font-weight: 700;
				}
			}
		}
	}
	a {
		color: $secondary;
		text-decoration: none;

		&:hover,&:focus {
			color: $on-primary;
		}
	}
	table {
		border-color: $on-primary;
		color: $secondary;
		margin: $spacing-unit*4 0;

		th,td {
			background: none;
			border-color: $on-primary;
			color: $on-primary;
		}			
		tr:nth-child(even) {
			background: none;
		}
	}
	li {
		margin-bottom: $spacing-unit;
	}
}

/* 404 Page
------------------------------*/

.page-404 {
	background-color: $primary;
	background-image: url(/images/2024/backgrounds/BLAST_PINK.svg);
	background-position: top center;
	background-repeat: no-repeat;
	background-size: contain;
	text-align: center;

	p, h2 {
		color: $on-primary;
	}
	h1 {
		color: $secondary;
	}
}