/* Blocks
==============================*/

.block {
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	width: 100%;
	
	.wrapper {
		padding-top: $spacing-unit*12;
		padding-bottom: $spacing-unit*12;
		
		
		@include media-query($on-1040){
			padding-top: $spacing-unit*8;
			padding-bottom: $spacing-unit*8;
		}
	}

	.wrapper-A {
		padding-bottom: 0;
	}

	.wrapper-B {
		padding-top: 0;
	}

	.block-left {
		float: left;
		width: 48%;
		
		@include media-query($on-800) {
			float: none;
			margin-bottom: $spacing-unit*4;
			width: 100%;
			
			img {
				max-height: 500px;
			}
		}
	}
	.block-right {
		float: right;
		width: 48%;
		
		@include media-query($on-800) {
			float: none;
			margin-bottom: $spacing-unit*4;
			width: 100%;
			
			img {
				max-height: 500px;
			}
		}
	}
	.title-card {
		padding: 0 0 $spacing-unit*4;
	}
}

.dark-bg {
	color: $positive;
}

.light-bg {
	color: $negative;

	a {
		color: $primary;

		&:hover,&:focus {
		  color: $negative;
		}

		&:visited {
		  color: $primary;
		}
	}
}

/* Home Blocks
------------------------------*/

.batman-at-the-castle {
	background-color: $secondary;
    background-image: url(/images/2024/backgrounds/BLAST_YELLOW.svg);
	background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
	color: $on-secondary;
	text-align: center;

	h2 {
		color: $primary;
		line-height: 1;
		margin-bottom: 0;
	}
	h4 {
		color: $primary;
		line-height: 1;
	}
	a {
		display: inline-block;

		&:hover,
		&:focus {
			background: $primary;
			color: $positive;
		}
	}

	.small {
		font-size: 0.8em;
		margin-top: 40px;
	}
}

.charities {
	text-align: center;
	
	.charity-logos {
		margin: $spacing-unit*5 auto;
		max-width: 900px;
		@extend %clearfix;
			
		@include media-query($on-800){
			max-width: 400px;
			
			.block-left {
				margin-bottom: $spacing-unit*6;
			}
		}
		
		img {
			max-width: 90%;
		}
	}
}

.contact {	
	background-color: $primary;
    background-image: url(/images/2024/banner/DOTS_PINK.svg), url(/images/2024/banner/DOTS_PINK.svg);
  	background-repeat: no-repeat, no-repeat;
  	background-size: 1200px;
  	background-position: left -600px top -600px, right -600px bottom -600px;
	
	a {
		color: $secondary;
		text-decoration: none;

		&:hover,&:focus {
			color: $on-primary;
		}
	}
	img {
		display: block;
		max-width: 400px;
		margin: 0 auto $spacing-unit*4;
		width: -webkit-fill-available;
	}
	h2 {
		color: $secondary;
		text-align: center;
	}
	p {
		color: $on-primary;
		margin: 0 auto;
		max-width: 600px;
	}
	#contact-form {
		color: $on-primary;
		margin: $spacing-unit*4 auto 0;
		max-width: 600px;
		width: 100%;

		label {
			display: block;
			font-size: 0.8em;
			margin-bottom: $spacing-unit/2;
		}
		
		input, textarea {
			background: none;
			border: 1px solid $on-primary;
			border-radius: $spacing-unit;
			color: $on-primary;
			max-width: calc(100% - 40px);
			
			&::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
				color: $on-primary;
				opacity: 1; /* Firefox */
			}
			&:-ms-input-placeholder { /* Internet Explorer 10-11 */
				color: $on-primary;
			}
			&::-ms-input-placeholder { /* Microsoft Edge */
				color: $on-primary;
			}
		}
		input[type="submit"]{
			background: $negative;
			border: none;
			border-radius: 100px;
			color: $secondary;
			margin: $spacing-unit*4 0 0;
			
			&:hover,
			&:focus {
				color: $positive;
			}
		}
	}

	@include media-query($on-800) {
		background-size: 800px;
		background-position: left -400px top -400px, right -400px bottom -400px;

	}

	@include media-query($on-600) {
		background-size: 600px;
		background-position: left -300px top -300px, right -300px bottom -300px;

	}

	@include media-query($on-400) {
		background-size: 400px;
		background-position: left -200px top -200px, right -200px bottom -200px;

	}
}

.dont-panic {	
	background-color: $primary;
    background-image: url(/images/2024/backgrounds/BLAST_PINK.svg);
    background-repeat: no-repeat;
    background-size: cover;
	text-align: center;
	
	a {
		color: $secondary;
		text-decoration: none;

		&:hover,&:focus {
			color: $positive;
		}
	}
	p {
		color: $on-primary;
	}
	img {
		margin-bottom: $spacing-unit*4;
	}
}

.fancy-dress {
	background: $primary;
  	background-image: url(/images/2024/banner/DOTS_PINK.svg), url(/images/2024/banner/DOTS_PINK.svg);
  	background-repeat: no-repeat, no-repeat;
  	background-size: 1000px;
  	background-position: left -500px top -500px, right -500px bottom -500px;

	@include media-query($on-800) {
		background-size: 800px;
		background-position: left -400px top -400px, right -400px bottom -400px;
	}

	@include media-query($on-600) {
		background-size: 600px;
		background-position: left -300px top -300px, right -300px bottom -300px;
	}

	@include media-query($on-400) {
		background-size: 400px;
		background-position: left -200px top -200px, right -200px bottom -200px;
	}
	
	a {
		color: $secondary;
		text-decoration: none;

		&:hover,&:focus {
			color: $positive;
		}
	}
	h2 {
		text-align: center;
		color: $secondary;
		margin-bottom: 0;
	}
	p {
		color: $on-primary;
	}
	// img {
	// 	display: block;
	// 	margin: 0 auto 40px;
	// 	max-height: 600px;
	// 	width: 100%;
	// }

	.fd-slider {
		margin-top: -20px;
	}

	.fd-image-row {
		display: flex;
		gap: 60px;
		// opacity: 0;
		transform: translateY(50px);
		transition: opacity 0.5s ease, transform 0.5s ease;
		width: 100%; /* Ensure the container takes full width */
		justify-content: center; /* Center the images in the row */
		margin-bottom: 80px;
		overflow: hidden;

		@include media-query($on-800) {
			gap: 20px;
		}

		@include media-query($on-600) {
			gap: 10px;
		}
	}
	
	.fd-image {
		width:200px;
		height: 400px;
		object-fit: contain;
		display: block; /* Ensure images are block elements */

		@include media-query($on-800) {
			width: 150px;
			height: 300px;
		}

		@include media-query($on-600) {
			width: 100px;
			height: 200px;
		}

		@include media-query($on-400) {
			width: 75px;
			height: 150px;
		}
	}
	
	.fd-left {
		transform: translateX(-100vw);
		transition: transform 1s ease-out;
	}
	
	.fd-right {
		transform: translateX(100vw);
		transition: transform 1s ease-out;
	}
	
	.fd-scrolled .fd-left {
		transform: translateX(0);
	}
	
	.fd-scrolled .fd-right {
		transform: translateX(0);
	}
	
	.fd-scrolled .fd-center {
		transform: translateY(0);
	}
	
	.fd-scrolled .fd-image-row {
		opacity: 1;
		transform: translateY(0);
	}

	table {
		border-color: $secondary;
		color: $secondary;
		margin: 0 0 $spacing-unit*4;

		th,td {
			background: none;
			border-color: $secondary;
			color: $secondary;
		}
		tr:nth-child(even) {
			background: none;
		}
	}
}

.firstsite {
	background-color: $primary;
	color: $on-primary;
	text-align: center;
	
	h3,h4 {
		height: 0;
		margin: 0;
		opacity: 0;
		padding: 0;
	}
	.wrapper {
		padding-top: 0;
	}
}

.guests {
	background-image: url('/images/2024/social/20190801SG.jpg');
	background-position: top;
	color: $secondary;
	
	a {
		color: $secondary;
		text-decoration: none;
		
		&:hover,
		&:focus {
			color: $positive;
		}
	}
	
	.wrapper {
		padding: $spacing-unit*50 $spacing-unit*2 $spacing-unit*2;
		
		@include media-query($on-1000) {
			padding-top: $spacing-unit*30;		
		}
		@include media-query($on-800) {
			padding-top: $spacing-unit*20;
			padding-bottom: 0;
		}
		@include media-query($on-500) {
			padding-top: $spacing-unit*10;
		}
	}
}

.guide {
	background-image: url('/images/2024/backgrounds/COL-HIGH-ST.jpg');
    background-attachment: fixed;
	color: $on-secondary;
	text-align: center;

	h3 {
		color: $primary;
	}

	h4 {
		margin-bottom: 0;
	}

	h5 {
		margin-bottom: 12px
	}

	p {
		margin-bottom: 18px
	}

	a,a.visited {
		color: $primary;
		font-weight: 700;
		text-decoration: none;

		&.hover,
		&.visited {
			color: $on-secondary;
			text-decoration: none;
		}
	}
}

.intro {
	text-align: center;
	
	a {
		color: $secondary;
		text-decoration: none;

		&:hover,&:focus {
			color: $on-primary;
		}
	}
	h1,h5 {
		color: $secondary;		
	}	
	h2,h3,h4,p {
		color: $on-primary;
	}
	.deets p {
		font-weight: 700;
	}
	h2 {
		
		@include media-query($on-laptop) {
		  @include relative-font-size(2);
		}
	  }
	h5 {
		margin: 0 auto $spacing-unit*6;
		max-width: $spacing-unit*86;
	}
	img {
		display: block;
		margin: 60px auto $spacing-unit*2;
		max-width: 80vw;
	}
	.wrapper {
		padding-top: $spacing-unit*2;
		
		@include media-query($on-1200) {
			padding-top: 0;
		}
	}
}

.justgiving {
	background-color: $primary;
	text-align: center;
	
	.wrapper div {
		margin-bottom: $spacing-unit*6;
	}
	li img {
		box-shadow: 0px 5px 10px rgba(0,0,0,0.7);
		
		&:hover,
		&:focus {
			box-shadow: none;
		}
	}
}

.live {
	background-image: url('/images/2024/backgrounds/MUSIC.jpg');
    background-attachment: fixed;
	color: $negative;

	h2 {
		color: $on-secondary;
		text-align: center;
	}

	p {
		
		@include media-query($on-800) {
			text-align: center;
		}
	}

	svg {
		display: block;
		margin: 0 auto $spacing-unit*4;
		max-width: 300px;
		width: 100%;

		path {
			fill: $primary;
		}
	}
	
	table {
		border-color: $on-secondary;
		color: $primary;
		margin: 0 0 40px;

		th,td {
			background: none;
			border-color: $on-secondary;
			color: $primary;
		}
		tr:nth-child(even) {
			background: none;
		}
		
		@include media-query($on-800) {
			margin: 0 auto;
		}
	}
}

.map {
	/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#939598+0,131313+100 */
	background: #939598; /* Old browsers */
	background: -moz-linear-gradient(-45deg,  #939598 0%, #131313 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(-45deg,  #939598 0%,#131313 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(135deg,  #939598 0%,#131313 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#939598', endColorstr='#131313',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
	text-align: center;
	
	img {
		display: block;
		margin: $spacing-unit*4 auto;
	}
}

.meet-heroes {
	background-image: url('/images/2024/backgrounds/20190801DS.jpg');
	background-position: top;
	
	.wrapper {
		padding: $spacing-unit*40 $spacing-unit*2 $spacing-unit*6;
		
		@include media-query($on-1000) {
			padding-top: $spacing-unit*20;		
		}
	}
	ul {
		display: none;
	}
	
	@include media-query($on-700) {
		background-image: url('/images/2024/backgrounds/20190801DS2.jpg');
	}
	@include media-query($on-400) {
		background-image: url('/images/2024/backgrounds/20190801DS3.jpg');
	}
}

.othermonkey {	
	background-color: $secondary;
    background-image: url(/images/2024/backgrounds/BLAST_YELLOW.svg);
	background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
	color: $on-secondary;
	text-align: center;

	img {
		display: block;
		margin: 0 auto 60px;
		max-width: 400px;
	}

	a {
		color: $primary;
		text-decoration: none;

		&:hover,&:focus {
			color: $on-secondary;
		}
	}
}

.park-ride {
	background-color: $primary;
	text-align: center;
	
	a {
		color: $secondary;
		font-weight: 600;
		text-decoration: none;

		&:hover,&:focus {
			color: $on-primary;
		}
	}
	h3,p {
		color: $on-primary;
		text-align: center;
	}
	svg {
		display: block;
		margin: 0 auto $spacing-unit*4;
		max-width: 300px;
		width: 100%;

		path {
			fill: $secondary;
		}
	}
	small {
		color: $on-primary;
		display: block;
	}
	table {
		border-color: $on-primary;
		color: $secondary;

		th,td {
			background: none;
			border-color: $on-primary;
		}
		tr:nth-child(even) {
			background: none;
		}
	}
}

.photo-gallery {
	background-image: url('/images/2024/backgrounds/20190801PG.jpg');
    background-attachment: fixed;
	
	a.button {
  		background: $primary;
	}
	h2 {
		color: $positive;
	}
	.wrapper {
		padding: $spacing-unit*16 0;
		
		@include media-query($on-1040){
			padding: $spacing-unit*8 $spacing-unit*2;
		}
	}
	@include media-query($on-600){
		background-attachment: initial;
	}

}

.poster-print {
	background-color: $primary;
	
	img {
		display: block;
		box-shadow: -10px 10px 10px rgba(0,0,0,0.3);
		-ms-transform: rotate(-5deg); /* IE 9 */
		-webkit-transform: rotate(-5deg); /* Chrome, Safari, Opera */
		transform: rotate(-5deg);
		margin: 0 auto;
		max-width: 60%;
		width: 100%;
		
		@include media-query($on-800) {
			max-width: 240px;
		}
	}
}

.prize-draw {
	background-color: $secondary;
    background-image: url(/images/2024/backgrounds/BLAST_YELLOW.svg);
	background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
	color: $on-secondary;
	text-align: center;
	
	ul {
		text-align: center;
		list-style: none;

		li {
			.prize {
				font-size: 1.6em;
				font-weight: 700;
			}
			.donor {
				display: block;
				font-size: initial;

				.donor {
					display: inline;
					font-weight: 700;
				}
			}
		}
	}
	a {
		color: $primary;
		text-decoration: none;

		&:hover,&:focus {
			color: $on-secondary;
		}
	}
	table {
		border-color: $primary;
		color: $primary;
		margin: $spacing-unit*4 0;

		th,td {
			background: none;
			border-color: $primary;
			color: $primary;
		}			
		tr:nth-child(even) {
			background: none;
		}
	}
	li {
		margin-bottom: $spacing-unit;
	}
}

.show-cars {
	background-color: $primary;
	background-image: url(/images/2024/banner/DOTS_PINK.svg);
	background-repeat: no-repeat;
	background-size: contain;
	background-position: center;
	color: $on-primary;
	text-align: center;

	h2 {
		color: $secondary;
	}
	
	.show-car-slider-section {
		position: relative;
		width: 100%;
		overflow: hidden;
		display: flex;
		justify-content: space-around; /* Distribute space around the images */
		align-items: center;
		margin: 40px auto;
	}
	
	.show-car-slider-image-container {
		position: relative;
		width: 400px; /* Adjust width as needed */
		height: 240px; /* Adjust height as needed */
		margin: 0 20px;
		transform: translateX(100vw); /* Start completely off-screen to the right */
		transition: transform 1s ease-in-out;
	}
	
	.show-car-slider-image {
		width: 100%;
		height: 100%;
		object-fit: contain;
	}
		
	@include media-query($on-600) {

		.show-car-slider-image-container {
			height: 180px;
		}
		
		.final-show-car {
			display: none;
		}
	}	
}

.total {
	background-color: $secondary;

	h2,p {
		color: $on-secondary;
		text-align: center;
	}
	p.total {
		color: $primary;
		font-size: 15vw;
		font-weight: 900;
	}
	img {
		display: block;
		margin: 0 auto;
		max-width: 300px;
	}
}
