/* Scroll to top */
#top {
	position: absolute;
    top: 0;
}

/* Site header
==================== */

.preheader {
    background-color: $secondary;
	color: $primary;
    font-family: "Roboto", sans-serif;
    font-size: $spacing-unit*1.4;
	font-weight: 600;
    line-height: 2;
    text-align: center;
    margin-bottom: 0;
	
	p {
		line-height: 1.2;
		margin-bottom: 0;
	    padding: 6px;
	}
	a,a:visited {
		color: $primary;
		font-weight: 900;
		text-decoration: none;

		&:hover,&:focus {
			color: $on-secondary;
		}
	}
}

.alt-body .preheader {
    background-color: $primary;
	color: $secondary;

	a,a:visited {
		color: $secondary;

		&:hover,&:focus {
			color: $on-primary;
		}
	}
}

.subheader {
    background-color: $primary;
	color: $positive;
    font-family: "Anton", sans-serif;
    font-size: $spacing-unit*1.6;
	height: $spacing-unit*3;
    line-height: 2;
    margin-bottom: 0;
	position: absolute;
	text-align: center;
	top: 30px;
	width: 100%;
	z-index: 30;
}
.sub1 {
	display: block;
	@media screen and (max-width:370px) {
		display: none;
	}
}
.sub2 {
	display: none;
	@media screen and (max-width:370px) {
		display: block;
	}
}


/**
 * Site header
 */
.site-header {
	color: $negative;
	font-family: "Freeman", sans-serif;
	font-weight: 400;
	font-style: normal;
	min-height: $spacing-unit * 1.865;
	text-transform: uppercase;

  // Positioning context for the mobile navigation icon
	position: relative;
	
	// &.home-header {
	// 	background: initial;	
	// }
}

.site-title {
	background-size: contain;
    float: left;
	height: $spacing-unit*4;
	margin: $spacing-unit 0 $spacing-unit $spacing-unit/2;
	text-indent: -99999px;
	width: 400px;

	@include relative-font-size(1.625);
	
	@include media-query ($on-500){		
		background-size: contain;
		margin-left: $spacing-unit;	
		width: $spacing-unit*10;
	}
}

.site-nav {
  float: right;
  line-height: $base-line-height * $base-font-size * 2.25;
  z-index: 10;

  .nav-trigger {
      display: none;
  }

  .menu-icon,
  .close-icon {
    display: none;
  }

  .page-link {
    color: $primary;
    line-height: $base-line-height;
	text-decoration: none;

    // Gaps between nav items, but not on the last one
    &:not(:last-child) {
      margin-right: 20px;
    }
	&:hover,
	&:focus {
		color: $primary-alt;
		text-decoration: none;
	}
  }

  @include media-query($on-1000) {
    position: absolute;
    top: 9px;
    right: $spacing-unit / 2;
    //text-align: right;
	text-align: center;
	
    label[for="nav-trigger"] {
		display: block;
		float: right;
		margin-bottom: 12px;
		width: 36px;
		height: 36px;
		z-index: 2;
		cursor: pointer;
	  
		.menu-icon {
			display: block;
			float: right;
			width: 36px;
			height: 26px;
			line-height: 0;
			padding-top: 10px;
			text-align: center;

			> svg {
			  fill: $positive;
			}
		}
		
		.close-icon {
			display: none;
			float: right;
			width: 36px;
			height: 26px;
			line-height: 0;
			padding-top: 10px;
			text-align: center;

			> svg {
			  fill: $positive;
			}
		}
    }
	.nav-trigger:checked + .nav-label {
		.menu-icon {
			display: none;
		}
		.close-icon {
			display: block;
		}
	}    

    input ~ .trigger {
      clear: both;
      display: none;
    }

    input:checked ~ .trigger {
		//background: $negative;
		background: rgba(0,0,0,0.9);
		display: block;
		font-size: 2rem;
		border: 1px solid $positive;
		padding: $spacing-unit*3 $spacing-unit $spacing-unit*6;
		width: 100vw;
		position: absolute;
		right: -5px;
		top: 50px;
		
		@include media-query($on-1000) {
			min-height: 50vh;
		}
		.menu-icon {
			display: none;
		}
		.close-icon {
			display: block;
		}
    }

    .page-link {
      display: block;
      padding: 5px 10px;

      &:not(:last-child) {
        margin-right: 0;
      }
      margin-left: 20px;
    }
  }
}

.pink-header {

	.site-title {
		background: url(/images/2024/logo/IC-LOGO-FULL-2024.svg) no-repeat;

		@include relative-font-size(1.625);

		@include media-query ($on-500){		
			background: url(/images/2024/logo/IC-LOGO-SHORT-2024.svg) no-repeat;
		}
	}
	.page-link {
		color: $secondary;

		&:hover,
		&:focus {
			color: $positive;
		}
	}
	
	@include media-query($on-1000) {
				
		label[for="nav-trigger"] {
		  
			.menu-icon {
	
				> svg {
				  fill: $secondary;
				}
			}
			
			.close-icon {
	
				> svg {
				  fill: $secondary;
				}
			}
		}
	}
}

.yellow-header {

	.site-title {
		background: url(/images/2024/logo/IC-LOGO-FULL-2024-2.svg) no-repeat;

		@include relative-font-size(1.625);
			
		@include media-query ($on-500){		
			background: url(/images/2024/logo/IC-LOGO-SHORT-2024.svg) no-repeat;
		}
	}
	.page-link {
		color: $primary;

		&:hover,
		&:focus {
			color: $negative;
		}
		@include media-query($on-1000) {
			&:hover,
			&:focus {
			color: $positive;
		}
		}
	}
	
	@include media-query($on-1000) {
				
		label[for="nav-trigger"] {
		  
			.menu-icon {
	
				> svg {
				  fill: $primary;
				}
			}
			
			.close-icon {
	
				> svg {
				  fill: $primary;
				}
			}
		}
	}
}
