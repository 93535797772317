@charset "utf-8";

// Define defaults for each variable.

$base-font-family: "Saira", sans-serif;
$base-header-font-family:		'wood_stampregular', sans-serif;
$base-header-font-family-alt:		"Saira Condensed", "Russo One", sans-serif;
$base-font-size:   21px !default;
$base-font-weight: 400 !default;
$small-font-size:  $base-font-size * 0.875 !default;
$base-line-height: 1.4 !default;

$spacing-unit:     10px !default;

$h1:                $spacing-unit*3.2;
$h2:                $spacing-unit*2.8;
$h3:                $spacing-unit*2.4;
$h4:                $spacing-unit*2;
$h5:                $spacing-unit*1.8;

$positive:        #fff;
$negative:        #000;
$primary:         #EC008C;
$primary-alt:     #E77CB9;
$secondary:       #FFF200;
$secondary-alt:   #F2AF0D;

$on-primary:		    $positive;
$on-secondary:      $negative;

$link:              $secondary;
$hover:             $positive;

$table-text-align: left !default;

$on-1200:           1200px;
$on-1100:           1100px;
$on-1040:           1040px;
$on-1000:           1000px;
$on-900:            900px;
$on-800:            800px;
$on-700:            700px;
$on-600:            600px;
$on-500:            500px;
$on-400:            400px;
$on-300:            300px;
$on-200:            200px;
$on-100:            100px;

// Width of the content area
$content-width:    $on-1200 !default;

$on-laptop:        $on-800 !default;
$on-tablet:        $on-600 !default;
$on-palm:          $on-400 !default;
$on-mobile:        $on-300 !default;

// Use media queries like this:
// @include media-query($on-palm) {
//   .wrapper {
//     padding-right: $spacing-unit / 2;
//     padding-left: $spacing-unit / 2;
//   }
// }
@mixin media-query($device) {
  @media screen and (max-width: $device) {
    @content;
  }
}

@mixin relative-font-size($ratio) {
  font-size: $base-font-size * $ratio;
}

// Import partials.
@import
  "invasion/fonts",
  "invasion/base",
  "invasion/header",
  "invasion/footer",
  "invasion/layout",
  "invasion/logo",
  "invasion/blocks",
  "invasion/pages",
  "invasion/lightbox",
  "invasion/slider",
  "invasion/syntax-highlighting"
;
