/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on July 31, 2019 */



@font-face {
    font-family: 'wood_stampregular';
    src: url('woodstamp-webfont.woff2') format('woff2'),
         url('woodstamp-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

.russo-one-regular {
    font-family: "Russo One", sans-serif;
    font-weight: 400;
    font-style: normal;
  }

.saira-condensed-thin {
    font-family: "Saira Condensed", sans-serif;
    font-weight: 100;
    font-style: normal;
  }
  
  .saira-condensed-extralight {
    font-family: "Saira Condensed", sans-serif;
    font-weight: 200;
    font-style: normal;
  }
  
  .saira-condensed-light {
    font-family: "Saira Condensed", sans-serif;
    font-weight: 300;
    font-style: normal;
  }
  
  .saira-condensed-regular {
    font-family: "Saira Condensed", sans-serif;
    font-weight: 400;
    font-style: normal;
  }
  
  .saira-condensed-medium {
    font-family: "Saira Condensed", sans-serif;
    font-weight: 500;
    font-style: normal;
  }
  
  .saira-condensed-semibold {
    font-family: "Saira Condensed", sans-serif;
    font-weight: 600;
    font-style: normal;
  }
  
  .saira-condensed-bold {
    font-family: "Saira Condensed", sans-serif;
    font-weight: 700;
    font-style: normal;
  }
  
  .saira-condensed-extrabold {
    font-family: "Saira Condensed", sans-serif;
    font-weight: 800;
    font-style: normal;
  }
  
  .saira-condensed-black {
    font-family: "Saira Condensed", sans-serif;
    font-weight: 900;
    font-style: normal;
  }